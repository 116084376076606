import React, { useState } from 'react';
import { Grid, Typography, Link, Paper, Stack } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { AppType } from 'containers/common/dashboard/EcommerceProductListType';
import { useTranslation } from 'react-i18next';
import { DISCOUNT_COUPONS_NAVIGATION, PATH_USER } from 'routes/paths';
import { LoadingButton } from '@mui/lab';
import AppLogo from './AppLogo/AppLogo';
import usePortalId from 'hooks/portal/usePortalId';
import { useSnackbar } from 'notistack';

export const installApp = (connectionUrl: string) => {
  const token = window.localStorage.getItem('accessToken');
  window.location.href = connectionUrl + '&bearer=' + token;
};

export default function AppCard({ row }: { row: AppType }) {
  const portalId = usePortalId();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  if (row.is_live && row.is_active) {
    sessionStorage.setItem('automatedDocLink', row.doc_link);
  }

  const breakpoints = {
    small: {
      width: 320,
      height: 480,
    },
    medium: {
      width: 480,
      height: 640,
    },
    large: {
      width: 640,
      height: 800,
    },
  };

  let width: number, height: number;

  if (window.innerWidth <= breakpoints.small.width) {
    ({ width, height } = breakpoints.small);
  } else if (window.innerWidth <= breakpoints.medium.width) {
    ({ width, height } = breakpoints.medium);
  } else {
    ({ width, height } = breakpoints.large);
  }

  const checkStatusOnRedirect = (authWindow: any) => {
    const checkAuth = setInterval(() => {
      try {
        const authUrl = authWindow.location.href as string;

        if (!authUrl) {
          if (authWindow.closed) {
            clearInterval(checkAuth);
            authWindow = null;
            setLoading(false);
          }

          return;
        }

        if (authWindow.closed) {
          clearInterval(checkAuth);
          setLoading(false);
          authWindow = null;

          return;
        }

        if (authUrl.includes('token') || authUrl.includes('success')) {
          const params = new URLSearchParams(authUrl.split('?')[1]);
          if (params.has('success')) {
            setLoading(false);
            authWindow.close();
            clearInterval(checkAuth);
            window.location.reload();
          }
        } else if (authUrl.includes('message')) {
          // const params = new URLSearchParams(authUrl.split('?')[1]);
          enqueueSnackbar('Portal not connected', { variant: 'error' });
          authWindow.close();
          setLoading(false);
          clearInterval(checkAuth);
        }
      } catch (e) {
        console.log(e, 'not working');
        if (authWindow?.closed) {
          clearInterval(checkAuth);
          setLoading(false);
        }
      }
    }, 400);
  };

  const addPortal = () => {
    setLoading(true);
    // console.log('hello', process.env);
    const token = window.localStorage.getItem('accessToken');
    const hubSpotWindow = window.open(
      `${process.env.REACT_APP_PORTAL_CONNECTION_URL}&bearer=${token}`,
      '_blank',
      `width=${width}, height=${height}, left=${
        (window.screen.width - width) / 2
      }, top=${(window.screen.height - height) / 2}`
    );
    checkStatusOnRedirect(hubSpotWindow);
  };

  return (
    <Grid item xs={4} sm={4} md={4}>
      <Item
        sx={{
          border: 'black',
          height: '523px',
          boxShadow: '0px 12px 24px -4px rgba(0, 0, 0, 0.12)',
        }}
      >
        <Typography
          className='status-fp-gateway'
          sx={{
            width: '25%',
            float: 'right',
            lineHeight: '20px',
            textAlign: 'center',
            backgroundColor: 'rgb(84 214 44 / 16%)',
            fontSize: '12px',
            padding: '2px 10px',
            borderRadius: '5px',
            color: '#229A16',
            textTransform: 'capitalize',
          }}
        >
          {row.tags}
        </Typography>
        <div
          style={{
            marginTop: '60px',
          }}
        >
          <AppLogo appName={row.name}></AppLogo>
        </div>
        <div>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '30px',
              height: '70px',
            }}
            variant='h4'
          >
            {row.plan_count > 1 ? t('price_starting_from') : null}
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            variant='h4'
          >
            ${row.price}/{t('month')}
          </Typography>
        </div>
        <Typography
          sx={{
            margin: '0 auto',
            width: '75%',
            height: '100px',
            paddingTop: '60px',
          }}
        >
          {row.description}
        </Typography>
        {row.is_live ? (
          row.is_active ? (
            <div style={{ paddingTop: '60px' }}>
              <Stack>
                <Typography
                  variant='body2'
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    paddingBottom: '15px',
                  }}
                >
                  {row.name == 'automatedcoupon' ? (
                    <Link
                      sx={{ fontWeight: '15px' }}
                      variant='button'
                      href={DISCOUNT_COUPONS_NAVIGATION.general.createStore(
                        portalId
                      )}
                    >
                      {t('add_new_store')}
                    </Link>
                  ) : (
                    <Link
                      sx={{ fontWeight: '15px' }}
                      target='_blank'
                      variant='button'
                      href={PATH_USER.general.accountSettings(
                        portalId,
                        'billing'
                      )}
                    >
                      {t('manage_app')}
                    </Link>
                  )}

                  <Link
                    sx={{ fontWeight: '15px' }}
                    target='_blank'
                    variant='button'
                    href={row.doc_link}
                  >
                    {t('documentation')}
                  </Link>
                </Typography>
              </Stack>

              <LoadingButton
                fullWidth
                type='button'
                variant='contained'
                loading={loading}
                className='add-new-portal'
                onClick={() => addPortal()}
              >
                {t('add_new_portal')}
              </LoadingButton>
            </div>
          ) : (
            <div style={{ paddingTop: '92px' }}>
              <LoadingButton
                fullWidth
                type='button'
                variant='contained'
                loading={loading}
                onClick={() => addPortal()}
              >
                {t('install')}
              </LoadingButton>
            </div>
          )
        ) : (
          <div style={{ paddingTop: '98px' }}>
            <LoadingButton
              fullWidth
              type='button'
              variant='contained'
              loading={false}
              onClick={() => {}}
            >
              {t('coming_soon')}
            </LoadingButton>
          </div>
        )}
      </Item>
    </Grid>
  );
}
