import Router from 'routes';
import ThemeProvider from './theme';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from 'components/ThemeLocalization';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'shepherd.js/dist/css/shepherd.css';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ThemeColorPresets>
          <ThemeLocalization>
            <Router />
          </ThemeLocalization>
        </ThemeColorPresets>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
