import React, { FunctionComponent, useState } from 'react';
import {
  Grid,
  Typography,
  Paper,
  Stack,
  Button,
  Dialog,
  DialogTitle,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DoneIcon from '@mui/icons-material/Done';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { AppPlanData } from '../PaymentType';
import { LoadingButton } from '@mui/lab';
import { AppDetails } from 'containers/Apps/DiscountCouponsApp/DiscountCoupons/DiscountCouponsType';
import Image from 'components/Image';
import PlanImage from '../../../../../src/assets/images/plan.png';
import { PRIMARY } from 'theme/palette';
import { SCHEDULE_NOW_MEETING_URL, SUPPORT_URL } from 'routes/paths';
import { DialogContent } from '@mui/material';
import { DialogContentText } from '@mui/material';
import useResponsive from 'hooks/useResponsive';
type Props = {
  appPlan: AppPlanData;
  appDetails: AppDetails;
  updateSelectedPlan: (appPlan: AppPlanData) => void;
  currentPlanId: string | '';
  selectedPlanId?: string | '';
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const PlanCard: FunctionComponent<Props> = ({
  appPlan,
  appDetails,
  updateSelectedPlan,
  currentPlanId,
  selectedPlanId,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const smUp = useResponsive('up', 'sm');

  return (
    <>
      {appPlan.type === 'paid' ? (
        <Grid item xs={4} sm={4} md={4}>
          <Item
            sx={{
              border: 'black',
              height: 'auto',
              boxShadow: '0px 12px 24px -4px rgba(0, 0, 0, 0.12)',
            }}
          >
            <Typography
              className='status-fp-gateway'
              sx={{
                textTransform: 'capitalize',
                width: 'auto',
                float: 'right',
                lineHeight: '20px',
                textAlign: 'center',
                backgroundColor: 'rgb(84 214 44 / 16%)',
                fontSize: '12px',
                padding: '2px 10px',
                borderRadius: '5px',
                color: '#229A16',
              }}
            >
              {appPlan.tags}
            </Typography>
            <Stack
              sx={{
                marginTop: '60px',
                color: '#637381',
              }}
            >
              {appPlan.plan_name}
            </Stack>
            <div>
              <Typography
                sx={{
                  color: 'black',
                }}
                variant='h2'
              >
                <sup
                  style={{
                    fontSize: '20px',
                    color: '#637381',
                  }}
                >
                  {appPlan.show_price ? '$' : null}
                </sup>
                {appPlan.show_price ? (
                  appPlan.price
                ) : (
                  <Typography
                    sx={{
                      fontSize: '20px',
                      height: '65px',
                      margin: '0 auto',
                      width: '150px',
                    }}
                    variant='h3'
                  >
                    {t('unlimited') + ' ' + t('plan')}
                  </Typography>
                )}
                <sub
                  style={{
                    fontSize: '20px',
                    color: '#637381',
                  }}
                >
                  {appPlan.show_price ? <> /{t('month')} </> : null}
                </sub>
              </Typography>
            </div>
            <Image
              sx={{
                width: '50%',
                objectFit: 'contain',
                margin: '0 auto',
              }}
              visibleByDefault
              disabledEffect
              alt='register'
              src={PlanImage}
            />
            <Stack
              sx={{
                margin: '0 auto',
                width: '310px',
                paddingTop: '20px',
                height: 'auto',
                minHeight: '300px',
              }}
            >
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                }}
                aria-label='contacts'
              >
                {appPlan.feature_description.map(desc => {
                  return (
                    <ListItem disablePadding key={desc.text}>
                      <ListItemButton>
                        <ListItemIcon>
                          <DoneIcon />
                        </ListItemIcon>
                        <ListItemText
                          sx={
                            desc.active
                              ? { color: '#212b36' }
                              : { color: '#919eab' }
                          }
                          primary={desc.text}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Stack>
            <Stack sx={{ paddingTop: '10px' }}>
              {appPlan.show_price ? (
                <LoadingButton
                  fullWidth
                  type='button'
                  variant='contained'
                  loading={false}
                  onClick={() => updateSelectedPlan(appPlan)}
                  disabled={
                    appPlan._id === appDetails?.payment_plan?.plan_data?._id
                  }
                  sx={{
                    background:
                      appPlan._id === selectedPlanId ? PRIMARY.darker : PRIMARY,
                  }}
                >
                  {appPlan._id === appDetails?.payment_plan?.plan_data?._id
                    ? t('current_plan')
                    : currentPlanId === ''
                    ? t('choose') + ' ' + appPlan.plan_name
                    : appPlan._id === selectedPlanId
                    ? t('selected')
                    : t('select')}
                </LoadingButton>
              ) : (
                <Button
                  variant='contained'
                  // target='_blank'
                  onClick={() => setOpen(true)}
                  // href={SUPPORT_URL}
                  sx={{
                    background: PRIMARY,
                  }}
                >
                  {t('schedule_a_meeting')}
                </Button>
              )}
            </Stack>
          </Item>
        </Grid>
      ) : null}

      <Dialog
        open={open}
        maxWidth={false}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>{t('schedule_a_meeting')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <iframe
              src={SCHEDULE_NOW_MEETING_URL}
              style={{
                border: 0,
                width: smUp ? '560px' : 'auto',
                height: '80vh',
                paddingBottom: '25px',
                borderRadius: '8px',
              }}
              title='MakeWebBetter Scheduling'
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
