import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { HEADER, NAVBAR } from '../../config';
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import type { ShepherdOptionsWithType } from 'react-shepherd';
import { useShepherdTour } from 'react-shepherd';
import { UPDATE_PROFILE_API_URL } from 'api/ApiConstants/GeneralConstants';
import axiosInstance from 'utils/axios';
import useAuth from 'hooks/useAuth';
import CheckIcon from '@mui/icons-material/Check';

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: prop => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};

const newSteps: ShepherdOptionsWithType[] = [
  {
    id: 'welcome-element',
    classes: 'welcome-element',
    attachTo: { element: '', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.next();
        },
        text: `Let's Start`,
        classes: 'shepherd-next-button',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: `<b>Welcome to HubSpot Automated Coupons!</b>'`,
    text: `<p>Effortlessly generate <b>personalized coupon codes</b> using <b>HubSpot workflows</b>. Set your rules, automate discounts, and boost conversions—all within <b>HubSpot!</b></p>
           <ul className="welcome-message" style="padding-left: 20px;">
             <li>Convert more with unique coupons</li>
             <li>Recover abandoned carts</li>
             <li>Reward loyal customers</li>
             <li>Sync coupon marketing with CRM data</li>
           </ul>`,
  },
  {
    id: 'store',
    attachTo: { element: '.store-element', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-back-button',
        text: 'Back',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
        classes: 'shepherd-next-button',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: `<b>Connect Store</b>`,
    text: ['Connect store from this section'],
  },
  {
    id: 'coupon_rules',
    attachTo: { element: '.coupon_rule', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-back-button',
        text: 'Back',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
        classes: 'shepherd-next-button',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: `<b>Create coupon rule</b>`,
    text: ['Create coupon rules from here that how coupon works'],
  },
  {
    id: 'coupon-element',
    attachTo: { element: '.coupon-element', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-back-button',
        text: 'Back',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
        classes: 'shepherd-next-button',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: `<b>Coupons<b/>`,
    text: ['See all created coupons listing in this section'],
  },
  {
    id: 'config',
    attachTo: { element: '.config-element', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-back-button',
        text: 'Back',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
        classes: 'shepherd-next-button',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: `<b>Configuration</b>`,
    text: [
      'From here you can manage who can create coupons within HubSpot using CRM cards directly from this panel',
    ],
  },
  {
    id: 'user_account',
    attachTo: { element: '.user-account', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-back-button',
        text: 'Back',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
        classes: 'shepherd-next-button',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: `<b>Personal Information</b>`,
    text: [
      'You can edit your personal details and account passwords and check your billing information',
    ],
  },
  {
    id: 'intro',
    attachTo: { element: '.first-element', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-back-button',
        text: 'Back',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
        classes: 'shepherd-next-button',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Switch to another HubSpot Portal',
    text: [
      'If multiple portals are connected then switch to the other portal from here',
    ],
  },
  {
    id: 'schedule',
    attachTo: { element: '.schedule-element', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-back-button',
        text: 'Back',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
        classes: 'shepherd-next-button',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Schedule meeting with us',
    text: [
      'Schedule meeting with us if having any doubts that how our product works',
    ],
  },
  {
    id: 'connect-portal',
    attachTo: { element: '.add-new-portal', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-back-button',
        text: 'Back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Exit',
        type: 'cancel',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Connect portal',
    text: ['Connect multiple portals from here'],
  },
];

export default function DashboardLayout() {
  const tour = useShepherdTour({ tourOptions, steps: newSteps });
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [tourStart, setTourStart] = useState(false);
  const { themeLayout } = useSettings();

  const { user } = useAuth();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  async function myCustomFunction() {
    try {
      const respo = await axiosInstance.post(`${UPDATE_PROFILE_API_URL}`, {
        progress_settings: {
          Dashboard: true,
          'coupon-rule': false,
        },
      });
      // console.log('respo', respo);
      if (respo.data.success) {
        // enqueueSnackbar(respo.data.message, { variant: 'success' });
      }
    } catch (error) {}
  }

  useEffect(() => {
    setTimeout(() => {
      // if (!tourStart) {
      if (
        user?.new_user &&
        user?.hasOwnProperty('progress_settings') &&
        user?.progress_settings.Dashboard &&
        !tourStart
      ) {
        setTourStart(true);
        tour.start();
        tour.on('cancel', () => {
          myCustomFunction();
        });
      } else if (user?.new_user && !tourStart) {
        setTourStart(true);
        tour.start();
        tour.on('cancel', () => {
          console.log('Tour was canceled');
          myCustomFunction();
        });
      }
    }, 1000);
  }, []);

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader
          onOpenSidebar={() => setOpen(true)}
          verticalLayout={verticalLayout}
        />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
        )}

        <Box
          component='main'
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader
        isCollapse={isCollapse}
        onOpenSidebar={() => setOpen(true)}
      />

      <NavbarVertical
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
