import {
  PATH_USER,
  DISCOUNT_COUPONS_NAVIGATION,
  POINTS_AND_REWARDS_NAVIGATION,
} from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { TFunction } from 'react-i18next';
import { NavListProps } from 'components/nav-section/type';

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/images/icons/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_banking'),
  payments: getIcon('ic_payments'),
  forms: getIcon('ic_forms'),
  transaction: getIcon('ic_transaction'),
  discountCoupon: getIcon('ic_discountCoupon'),
  trophy: getIcon('ic_trophy'),
};

const navConfig = (
  portalId: string,
  activeApps: string[],
  t: TFunction<'translation', undefined>
) => {
  const navConfigArr: {
    subheader: string;
    items: NavListProps[];
  }[] = [];
  navConfigArr.push({
    subheader: t('apps'),
    items: [
      {
        title: t('all_apps'),
        path: PATH_USER.appListing(portalId),
        icon: ICONS.payments,
      },
    ],
  });
  if (activeApps.includes('automatedcoupon')) {
    navConfigArr.push({
      subheader: '',
      items: [
        {
          title: t('automated_coupon_code'),
          path: 'PATH_DASHBOARD.user.root',
          icon: ICONS.discountCoupon,
          children: [
            {
              title: t('stores'),
              path: DISCOUNT_COUPONS_NAVIGATION.general.couponStoreListingTabLinks(
                portalId,
                'stores'
              ),
              className: 'store-element',
            },
            {
              title: t('coupon_rules'),
              path: DISCOUNT_COUPONS_NAVIGATION.general.couponStoreListingTabLinks(
                portalId,
                'coupon-rules'
              ),
              className: 'coupon_rule',
            },
            {
              title: t('coupons'),
              path: DISCOUNT_COUPONS_NAVIGATION.general.couponStoreListingTabLinks(
                portalId,
                'coupons'
              ),
              className: 'coupon-element',
            },
            {
              title: t('config'),
              path: DISCOUNT_COUPONS_NAVIGATION.general.couponStoreListingTabLinks(
                portalId,
                'configuration'
              ),
              className: 'config-element',
            },
          ],
        },
      ],
    });
  }

  if (activeApps.includes('pointsandreward')) {
    navConfigArr.push({
      subheader: '',
      items: [
        {
          title: t('points_and_rewards'),
          path: 'PATH_DASHBOARD.user.root',
          icon: ICONS.trophy,
          children: [
            {
              title: t('rules'),
              path: POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
                portalId,
                'rules'
              ),
            },
            {
              title: t('rewards'),
              path: POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
                portalId,
                'rewards'
              ),
            },
            {
              title: t('customer'),
              path: POINTS_AND_REWARDS_NAVIGATION.general.pointsRewardsListingTabLinks(
                portalId,
                'customer'
              ),
            },
            {
              title: t('settings'),
              path: POINTS_AND_REWARDS_NAVIGATION.general.settings(
                portalId,
                'store-setup'
              ),
            },
          ],
        },
      ],
    });
  }
  navConfigArr.push({
    subheader: t('manage'),
    items: [
      {
        title: t('user_accounts'),
        path: PATH_USER.general.accountSettings(portalId, 'general'),
        icon: ICONS.user,
        className: 'user-account',
      },
    ],
  });

  return navConfigArr;
};

export default navConfig;
