/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { CouponRuleType } from '../../DiscountCouponsType';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker } from '@mui/lab';
import LoadingButton from '@mui/lab/LoadingButton';

import {
  Alert,
  Box,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  Backdrop,
} from '@mui/material';
import {
  CREATE_COUPON_API_URL,
  UPDATE_COUPON_RULE_API_URL,
} from 'api/ApiConstants/DiscountCouponsApiConstants';
import { useWooCommerceStores } from 'api/ApiHooks/DiscountCouponsApiHooks';
import ComboBox, { CrmComboBoxType } from 'components/ComboBox';
import PartialPageSpinner from 'components/PartialPageSpinner';
import usePortalId from 'hooks/portal/usePortalId';
import i18next from 'i18next';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AUTOMATEDCOUPON, DISCOUNT_COUPONS_NAVIGATION } from 'routes/paths';
import { isApiError } from 'utils/apiFunctions';
import axiosInstance from 'utils/axios';
import * as Yup from 'yup';
import {
  FormProvider,
  RHFCheckbox,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from 'components/hook-form';
import { fDateToYMD } from 'utils/formatTime';
import Iconify from 'components/Iconify';
import {
  useWooBrands,
  useWooProducts,
  useWooCategory,
} from 'api/ApiHooks/GeneralHooks';
import {
  IWooBrands,
  IWooExcludedProducts,
  IWooProducts,
  IWooCategory,
} from 'containers/common/dashboard/EcommerceProductListType';
import type { ShepherdOptionsWithType } from 'react-shepherd';
import { useShepherdTour } from 'react-shepherd';
import { UPDATE_PROFILE_API_URL } from 'api/ApiConstants/GeneralConstants';
import useAuth from 'hooks/useAuth';

const AddCouponSchema = Yup.object().shape({
  couponUsageLimit: Yup.number().when('couponUsage', {
    is: 'limited',
    then: Yup.number()
      .typeError(i18next.t('specify_number'))
      .integer()
      .required(i18next.t('valid_prefix_required'))
      .min(1, i18next.t('min_value') + ' 1'),
  }),
  relative: Yup.number().when('activeDates', {
    is: 'relative',
    then: Yup.number()
      .typeError(i18next.t('specify_number'))
      .integer()
      .required(i18next.t('valid_prefix_required'))
      .min(1, i18next.t('min_value') + ' 1'),
  }),
  store: Yup.number()
    .not([-1], i18next.t('store_required'))
    .required(i18next.t('store_required')),
  couponName: Yup.string().required(i18next.t('coupon_name_required')),
  prefix: Yup.string()
    .required(i18next.t('valid_prefix_required'))
    .max(6, i18next.t('max_value') + ' 6')
    .min(1, i18next.t('min_value') + ' 1'),
  mergeFields: Yup.string().not(['-1'], i18next.t('merge_field_required')),
  randomCharacters: Yup.string().required(
    i18next.t('random_characters_required')
  ),
  suffix: Yup.string()
    .required(i18next.t('valid_suffix_required'))
    .max(6, i18next.t('max_value') + ' 6')
    .min(1, i18next.t('min_value') + ' 1'),
  format: Yup.string().not(['-1'], i18next.t('format_required')),
  discountType: Yup.string().not(['-1'], i18next.t('discount_required')),
  discountValue: Yup.number()
    .label(i18next.t('Discount Value'))
    .when('discountType', {
      is: 'percent',
      then: Yup.number()
        .typeError(i18next.t('specify_number'))
        .max(100, i18next.t('max_value') + ' 100')
        .min(1, i18next.t('min_value') + ' 0.01'),
    }),
});

type FormValuesProps = {
  store: number;
  couponName: string;
  mergeFields: string;
  format: string;
  randomCharacters: string;
  suffix: string;
  discountType: string;
  alwaysActive: string;
  activeDates: string;
  appliesTo: string;
  discountValue: number;
  afterSubmit?: string;
  selectedProducts: IWooProducts[];
  prefix: string;
  relative: number;
  couponUsageLimit: number;
  couponUsage: string;
  customNameProperty: string;
  excludeSaleItems: boolean;
  freeShipping: boolean;
  individualCoupon: boolean;
};

type Props = {
  type: 'add' | 'edit';
  couponDetails?: CouponRuleType;
};

const getSampleCode = ({
  format = '',
  prefix = '',
  mergeField = '',
  suffix = '',
  randomChars = '',
}) => {
  if (mergeField === '-1') {
    return '7BC6EF';
  }

  if (format === 'prefix_MergeField') {
    return (
      (prefix ? prefix + '_' : '') +
      mergeField +
      '_' +
      randomChars +
      (suffix ? '_' + suffix : '')
    );
  }

  if (format === 'MergeField_prefix') {
    return (
      mergeField +
      '_' +
      (prefix ? prefix + '_' : '') +
      randomChars +
      (suffix ? '_' + suffix : '')
    );
  }

  if (format === 'prefix') {
    return (
      (prefix ? prefix + '_' : '') + randomChars + (suffix ? '_' + suffix : '')
    );
  }

  if (format === 'MergeField') {
    return mergeField + '_' + randomChars + (suffix ? '_' + suffix : '');
  }
};

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};

const newSteps: ShepherdOptionsWithType[] = [
  {
    id: 'form-intro',
    attachTo: { element: '.first-element-form', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.next();
        },
        text: 'Next',
        classes: 'shepherd-next-button',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Coupon Name',
    text: ['Enter a name for the coupon to identify it easily.'],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      },
    },
  },
  {
    id: 'schedule',
    attachTo: { element: '.second-element-form', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-back-button',
        text: 'Back',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
        classes: 'shepherd-next-button',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Store Selection',
    text: ['Please select the store to which you want to apply the coupon'],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      },
    },
  },
  {
    id: 'connect-portal',
    attachTo: { element: '.third-form-element', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-back-button',
        text: 'Back',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
        classes: 'shepherd-next-button',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Coupon Format',
    text: ['In this section, you can adjust the formatting of your coupon.'],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      },
    },
  },
  {
    id: 'store',
    attachTo: { element: '.forth-form-element', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-back-button',
        text: 'Back',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
        classes: 'shepherd-next-button',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Type Of Discount',
    text: [
      'From here you can specify how the coupon works either flat or percentage discount',
    ],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      },
    },
  },
  {
    id: 'coupon_rules',
    attachTo: { element: '.fifth-form-element', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-back-button',
        text: 'Back',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
        classes: 'shepherd-next-button',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Select Brand, Product and category',
    text: [
      'In this section, you can include or exclude the brands, products, and categories to which the coupon applies.',
    ],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      },
    },
  },
  {
    id: 'coupon_rules',
    attachTo: { element: '.sixth-form-element', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-back-button',
        text: 'Back',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
        classes: 'shepherd-next-button',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Valid Upto',
    text: ['You can specify from here when will be coupon gets expire'],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      },
    },
  },
  {
    id: 'coupon_rules',
    attachTo: { element: '.seventh-form-element', on: 'bottom' },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-back-button',
        text: 'Back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Exit',
        type: 'cancel',
      },
    ],
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Usage of the coupons',
    text: [
      'From here you can set a limit on the coupon that how many times the coupon works',
    ],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      },
    },
  },
];

const CouponForm: FunctionComponent<Props> = ({ type, couponDetails }) => {
  const tour = useShepherdTour({ tourOptions, steps: newSteps });
  const { t } = useTranslation();
  const portalId = usePortalId();
  const { data } = useWooCommerceStores(Number(portalId), AUTOMATEDCOUPON);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [tourStart, setTourStart] = useState(false);
  const [searchProductVal, setSearchProductVal] = useState('');
  const [searchCategoryVal, setSearchCategoryVal] = useState('');
  const [searchBrandVal, setSearchBrandVal] = useState('');
  const [searchExcludeProduct, setSearchExcludeProduct] = useState('');
  const [searchExcludeCategory, setSearchExcludeCategory] = useState('');
  const [productOptions, setProductsOptions] = useState<IWooProducts[] | []>(
    []
  );
  const [excludeProductOption, setExcludeProductOption] = useState<
    IWooProducts[] | []
  >([]);
  const [categoryOption, setCategoryOption] = useState<IWooCategory[] | []>([]);
  const [excludeCategoryOption, setExcludeCategoryOption] = useState<
    IWooCategory[] | []
  >([]);
  const [brandsOptions, setBrandsOptions] = useState<IWooBrands[] | []>([]);
  const [submitting, setSubmitting] = useState(false);

  const [activeDate, setActiveDate] = useState<string | null>(
    couponDetails?.expiresAt
      ? new Date(parseInt(couponDetails?.expiresAt)).toISOString().slice(0, 10)
      : new Date(Date.now() + 3600 * 1000 * 24).toISOString().slice(0, 10) ||
          null
  );

  const [selectedProducts, setSelectedProducts] = useState<IWooProducts[] | []>(
    couponDetails?.productIds ? couponDetails.productIds : []
  );

  const [excludedProducts, setExcludedProducts] = useState<
    IWooExcludedProducts[] | []
  >(couponDetails?.excludeProductIds ? couponDetails.excludeProductIds : []);

  const [selectedCategory, setSelectedCategory] = useState<IWooCategory[] | []>(
    couponDetails?.productCategoryId ? couponDetails.productCategoryId : []
  );

  const [excludedCategory, setExcludedCategory] = useState<IWooCategory[] | []>(
    couponDetails?.excludeProductCategoryId
      ? couponDetails.excludeProductCategoryId
      : []
  );

  const [selectedBrand, setSelectedBrand] = useState<IWooBrands[] | []>(
    couponDetails?.brands ? couponDetails.brands : []
  );

  const [productValue, setProductValue] = useState<any>({
    includedProduct: [],
    excludedProducts: [],
  });

  const [categoryValue, setCategoryValue] = useState<any>({
    includedCategory: [],
    excludedCategory: [],
  });

  const setProducts = (products: any) => {
    let proExist = false;

    if (excludedCategory.length) {
      excludedCategory.map(item => {
        products.map((prod: any) => {
          prod.categories.map((cate: any) => {
            if (cate.id == item.id) {
              proExist = true;
              enqueueSnackbar(t('you_can_not_select_this_product_message'), {
                variant: 'warning',
              });
              return;
            }
          });
        });
      });
    }

    let newArr: any[] = products;
    if (products.length && !proExist) {
      products.forEach((item: any) => {
        if (productValue.excludedProducts.includes(item.id)) {
          newArr = products.filter((item2: any) => {
            return item2.id != item.id;
          });
          enqueueSnackbar(t('included_product_warning'), {
            variant: 'warning',
          });
          return;
        }
      });
    }
    if (!proExist) {
      setProductValue({
        ...productValue,
        includedProduct: newArr.map((product: any) => product.id),
      });
      setSelectedProducts(newArr);
    }
  };

  const setExcludeProduct = (products: any) => {
    let newArr: any[] = products;
    if (products.length) {
      products.forEach((item: any) => {
        if (productValue.includedProduct.includes(item.id)) {
          newArr = products.filter((item2: any) => {
            return item2.id != item.id;
          });
          enqueueSnackbar(t('excluded_product_warning'), {
            variant: 'warning',
          });
          return;
        }
      });
    }

    setProductValue({
      ...productValue,
      excludedProducts: newArr.map((product: any) => product.id),
    });
    setExcludedProducts(newArr);
  };

  const setCategory = (category: any) => {
    let newArr: any[] = category;
    if (category.length) {
      category.forEach((item: any) => {
        if (categoryValue.excludedCategory.includes(item.id)) {
          newArr = category.filter((item2: any) => {
            return item2.id != item.id;
          });
          enqueueSnackbar(t('included_category_warning'), {
            variant: 'warning',
          });
          return;
        }
      });
    }
    setCategoryValue({
      ...categoryValue,
      includedCategory: newArr.map((product: any) => product.id),
    });
    setSelectedCategory(newArr);
  };

  const setExcludeCategory = (category: any) => {
    let proExist = false;

    if (selectedProducts.length) {
      selectedProducts.map(item => {
        item.categories.map((cate: any) => {
          category.map((item2: any) => {
            if (item2.id == cate.id) {
              enqueueSnackbar(t('you_can_not_exclude_category_message'), {
                variant: 'warning',
              });
              proExist = true;
              return;
            }
          });
        });
      });
    }

    let newArr: any[] = category;
    if (category.length && !proExist) {
      category.forEach((item: any) => {
        if (categoryValue.includedCategory.includes(item.id)) {
          newArr = category.filter((item2: any) => {
            return item2.id != item.id;
          });
          enqueueSnackbar(t('excluded_category_warning'), {
            variant: 'warning',
          });
          return;
        }
      });
    }

    if (!proExist) {
      setCategoryValue({
        ...categoryValue,
        excludedCategory: newArr.map((product: any) => product.id),
      });

      setExcludedCategory(newArr);
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    couponName: couponDetails?.couponName || '',
    store: Number(couponDetails?.storeId) || -1,
    couponUsage:
      couponDetails?.usageLimit === null
        ? 'unlimited'
        : 'limited' || 'unlimited',
    couponSnippet: '',
    mergeFields: couponDetails?.couponCodeMiddle || '-1',
    format: couponDetails?.couponCodeRandomFormat || '-1',
    discountType: couponDetails?.discountType || 'flat',
    prefix: couponDetails?.couponCodePrefix || '',
    discountValue: couponDetails?.discountValue || 0,
    randomCharacters: 'AUTOGENERATED',
    selectedProducts: couponDetails?.productIds
      ? couponDetails.productIds
      : [] || [],
    activeDates:
      couponDetails?.expiresType === 'date'
        ? 'expiresAt'
        : couponDetails?.expiresType === 'days'
        ? 'relative'
        : couponDetails?.expiresAt
        ? 'expiresAt'
        : 'alwaysActive',
    appliesTo: couponDetails?.couponCodeAppliesTo
      ? 'selectedProducts'
      : 'entireProducts' || 'entireProducts',
    suffix: couponDetails?.couponCodeSuffix || '',
    relative:
      couponDetails?.expiresType === 'days' ? +couponDetails?.expiresAt : 0,
    couponUsageLimit: couponDetails?.usageLimit || 0,
    customNameProperty: couponDetails?.customNameProperty || '',
    excludeSaleItems: couponDetails?.excludeSaleItems || false,
    freeShipping: couponDetails?.freeShipping || false,
    individualCoupon: couponDetails?.individualCoupon || false,
  };

  const methods = useForm<FormValuesProps>({
    mode: 'all',
    resolver: yupResolver(AddCouponSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = methods;

  const {
    data: wooProducts,
    fetchNextPage,
    isFetching,
  } = useWooProducts({
    search: searchProductVal || searchExcludeProduct,
    storeId: Number(watch('store')),
    appCode: AUTOMATEDCOUPON,
    portalId: Number(portalId),
    brand: '' || selectedBrand ? selectedBrand.map(item => item.name) : [],
  });

  const {
    data: wooCategory,
    fetchNextPage: fetchMorePage,
    isFetching: fetchingCategory,
  } = useWooCategory({
    search: searchCategoryVal || searchExcludeCategory,
    storeId: Number(watch('store')),
    appCode: AUTOMATEDCOUPON,
    portalId: Number(portalId),
    // brand: '' || selectedBrand ? selectedBrand.map(item => item.name) : [],
  });

  const {
    data: wooBrands,
    isFetching: fetching,
    isLoading,
  } = useWooBrands({
    search: searchBrandVal,
    storeId: Number(watch('store')),
    appCode: AUTOMATEDCOUPON,
    portalId: Number(portalId),
  });

  const setBrands = (brands: any) => {
    setSelectedBrand(brands);
  };

  const createCoupon = async (data: FormValuesProps) => {
    setSubmitting(true);
    try {
      const result = await axiosInstance.post(CREATE_COUPON_API_URL, {
        portalId: Number(portalId),
        storeId: Number(data.store),
        couponName: data.couponName,
        couponCodeRandomChars: data.randomCharacters,
        discountType: data.discountType,
        discountValue: data.discountValue,
        couponCodeMiddle: data.mergeFields,
        couponCodeAppliesTo:
          data.appliesTo === 'selectedProducts' ? true : false,
        couponCodePrefix: data.prefix,
        couponCodeSuffix: data.suffix,
        couponCodeRandomFormat: data.format,
        productIds: selectedProducts
          ? selectedProducts.map(product => product.id)
          : [],
        brands: selectedBrand ? selectedBrand.map(brand => brand.id) : [],
        excludeProductIds: excludedProducts
          ? excludedProducts.map(brand => brand.id)
          : [],
        productCategoryId: categoryValue.includedCategory,
        excludeProductCategoryId: categoryValue.excludedCategory,
        expiresType:
          data.activeDates === 'expiresAt'
            ? 'date'
            : data.activeDates === 'relative'
            ? 'days'
            : 'no_expire',
        expiresAt:
          data.activeDates === 'expiresAt' && activeDate !== null
            ? fDateToYMD(activeDate.toString())
            : data.activeDates === 'relative'
            ? data.relative
            : null,
        usageLimit:
          data.couponUsage === 'limited' ? data.couponUsageLimit : null,
        customNameProperty: data?.customNameProperty,
        excludeSaleItems: data.excludeSaleItems,
        freeShipping: data.freeShipping,
        individualCoupon: data.individualCoupon,
      });
      if (result.data.success) {
        setSubmitting(false);
        navigate(
          DISCOUNT_COUPONS_NAVIGATION.general.couponStoreListingTabLinks(
            portalId,
            'coupon-rules'
          )
        );
      }
    } catch (error) {
      if (isApiError(error)) {
        setSubmitting(false);
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };

  const updateCoupon = async (data: FormValuesProps) => {
    setSubmitting(true);
    try {
      const result = await axiosInstance.post(
        UPDATE_COUPON_RULE_API_URL + '/' + couponDetails?._id,
        {
          portalId: Number(portalId),
          storeId: Number(data.store),
          couponName: data.couponName,
          couponCodeRandomChars: data.randomCharacters,
          discountType: data.discountType,
          discountValue: data.discountValue,
          couponCodeMiddle: data.mergeFields,
          couponCodeAppliesTo:
            data.appliesTo === 'selectedProducts' ? true : false,
          couponCodePrefix: data.prefix,
          couponCodeSuffix: data.suffix,
          couponCodeRandomFormat: data.format,
          productIds: selectedProducts
            ? selectedProducts.map(product => product.id)
            : [],
          brands: selectedBrand ? selectedBrand.map(brand => brand.id) : [],
          excludeProductIds: excludedProducts
            ? excludedProducts.map(brand => brand.id)
            : [],
          productCategoryId: categoryValue.includedCategory,
          excludeProductCategoryId: categoryValue.excludedCategory,
          expiresType:
            data.activeDates === 'expiresAt'
              ? 'date'
              : data.activeDates === 'relative'
              ? 'days'
              : 'no_expire',
          expiresAt:
            data.activeDates === 'expiresAt' && activeDate !== null
              ? fDateToYMD(activeDate.toString())
              : data.activeDates === 'relative'
              ? data.relative
              : null,
          usageLimit:
            data.couponUsage === 'limited' ? data.couponUsageLimit : null,
          customNameProperty: data?.customNameProperty,
          excludeSaleItems: data?.excludeSaleItems,
          freeShipping: data.freeShipping,
          individualCoupon: data.individualCoupon,
        }
      );

      if (result.data.success) {
        setSubmitting(false);
        navigate(
          DISCOUNT_COUPONS_NAVIGATION.general.couponStoreListingTabLinks(
            portalId,
            'coupon-rules'
          )
        );
      }
    } catch (error) {
      if (isApiError(error)) {
        setSubmitting(false);
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };

  const onSubmit = (data: FormValuesProps) => {
    if (type === 'add') {
      createCoupon(data);
    } else {
      updateCoupon(data);
    }
  };

  const handleProductScrolledToBottom = () => {
    fetchNextPage();
  };

  const handleCategoryScrollToBottom = () => {
    fetchMorePage();
  };

  const brandOptions = React.useMemo(() => {
    const allProducts: IWooBrands[] = [];

    wooBrands?.pages.forEach(page => {
      allProducts.push(...page.data);
    });

    return allProducts;
  }, [wooBrands?.pages.length]);

  const products: IWooProducts[] = [];
  useEffect(() => {
    if (wooProducts?.pages.length) {
      wooProducts?.pages.forEach(page => {
        products.push(...page.data);
      });
    }
    setProductsOptions(products);
    setExcludeProductOption(products);
  }, [wooProducts?.pages]);

  const category: IWooCategory[] = [];
  useEffect(() => {
    if (wooCategory?.pages.length) {
      wooCategory?.pages.forEach(page => {
        category.push(...page.data);
      });
    }
    setCategoryOption(category);
    setExcludeCategoryOption(category);
  }, [wooCategory?.pages]);

  const brands: IWooBrands[] = [];
  useEffect(() => {
    if (wooBrands?.pages.length) {
      wooBrands?.pages.forEach(page => {
        brands.push(...page.data);
      });
    }
    setBrandsOptions(brands);
  }, [wooBrands?.pages]);

  async function myCustomFunction() {
    try {
      const respo = await axiosInstance.post(`${UPDATE_PROFILE_API_URL}`, {
        progress_settings: {
          Dashboard: true,
          'coupon-rule': true,
        },
      });
      if (respo.data.success) {
        // enqueueSnackbar(respo.data.message, { variant: 'success' });
      }
    } catch (error) {}
  }

  useEffect(() => {
    setTimeout(() => {
      if (data?.data.length) {
        if (
          data &&
          !user?.new_user &&
          user?.hasOwnProperty('progress_settings') &&
          !user?.progress_settings['coupon-rule'] &&
          !tourStart
        ) {
          setTourStart(true);
          tour.start();
          tour.on('cancel', () => {
            myCustomFunction();
          });
        } else if (user?.new_user && !tourStart) {
          setTourStart(true);
          tour.start();
          tour.on('cancel', () => {
            myCustomFunction();
          });
        }
      }
    }, 3000);
  }, [data]);

  if (!data) {
    return <PartialPageSpinner />;
  }

  const sampleCode = getSampleCode({
    format: watch('format'),
    mergeField: watch('mergeFields'),
    prefix: watch('prefix'),
    randomChars: watch('randomCharacters'),
    suffix: watch('suffix'),
  });

  // sample default random chars
  // prefix merge field = prefix + merge field + random chars + suffix
  // merge field prefix = merge field + prefix + random chars + suffix
  // prefix = prefix + random chars + suffix
  // merge field = merge field + random chars + suffix

  return (
    <div>
      {data.data.length ? (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {!!errors.afterSubmit && (
              <Alert severity='error' sx={{ alignItems: 'center' }}>
                {errors.afterSubmit.message}
              </Alert>
            )}

            <RHFTextField
              label={t('coupon_name')}
              name='couponName'
              type='text'
              className='first-element-form'
              placeholder={t('coupon_name')}
            />

            <RHFSelect
              name='store'
              placeholder={t('store')}
              className='second-element-form '
              label={t('store')}
            >
              <option value='-1' key='-1' className='text-gray-300'>
                {t('select_store')}
              </option>
              \{' '}
              {_.map(data.data, function (store, key) {
                if (store.isConnected)
                  return (
                    <option key={store._id} value={store._id}>
                      {store.storeUrl}
                    </option>
                  );
              })}
            </RHFSelect>
          </Stack>
          <div className='third-form-element'>
            <Typography variant='subtitle1' className='my-7'>
              {t('Coupon Code Format')}
            </Typography>

            <RHFTextField
              name='prefix'
              type='text'
              placeholder={t('prefix')}
              label={t('prefix')}
              inputProps={{ maxLength: 6 }}
            />

            <RHFSelect
              placeholder={t('store_source')}
              name='mergeFields'
              className='my-3'
              label={t('merge_fields')}
            >
              <option value='-1' className='text-gray-300' disabled>
                {t('none')}
              </option>
              <option value='firstName'>{t('first_name')}</option>
              <option value='lastName'>{t('last_name')}</option>
              <option value='email'>{t('email')}</option>
            </RHFSelect>

            <RHFTextField
              disabled
              name='randomCharacters'
              type='text'
              placeholder={t('random_characters')}
              className='mt-3'
              label={t('random_characters')}
            />

            <RHFTextField
              name='suffix'
              type='text'
              placeholder={t('suffix')}
              className='mt-3'
              label={t('suffix')}
              inputProps={{ maxLength: 6 }}
            />

            <RHFSelect
              name='format'
              label={t('format')}
              placeholder={t('format')}
              className='my-3'
            >
              <option value='-1' className='text-gray-300' disabled>
                {t('none')}
              </option>
              <option value='prefix_MergeField'>
                {t('prefix_merge_field')}
              </option>
              <option value='MergeField_prefix'>
                {t('merge_field_prefix')}
              </option>
              <option value='prefix'>{t('prefix')}</option>
              <option value='MergeField'>{t('merge_field')}</option>
            </RHFSelect>

            <div className='my-3 font-bold text-gray-400'>
              {t('sample')} : {sampleCode}{' '}
            </div>
          </div>

          <div className='forth-form-element'>
            <RHFSelect
              name='discountType'
              className='my-3'
              label={t('discount_type')}
            >
              <option value='-1' disabled className='text-gray-400'>
                {t('discount_type')}
              </option>
              <option value='flat'>{t('flat_discount')}</option>
              <option value='percent'>{t('percent_discount')}</option>
            </RHFSelect>

            <RHFTextField
              name='discountValue'
              type='number'
              label={t('discount_value')}
              placeholder={
                watch('discountType') === 'flat'
                  ? t('enter_value')
                  : t('enter_percentage')
              }
            />
          </div>
          <div className='fifth-form-element'>
            {brandOptions.length ? (
              <ComboBox
                loading={fetching}
                label={t('select_brand')}
                options={brandsOptions}
                values={selectedBrand}
                onChange={brands => setBrands(brands as CrmComboBoxType[])}
                inputSearchValue={searchBrandVal}
                onInputChange={setSearchBrandVal}
                multiple
                // scrolledToBottomCallback={handleProductScrolledToBottom}
              />
            ) : (
              isLoading && (
                <Backdrop
                  sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1,
                  }}
                  open={fetching}
                >
                  <CircularProgress color='inherit' />
                </Backdrop>
              )
            )}

            <Typography variant='subtitle1' className='mt-7'>
              {t('applies_to')}
            </Typography>

            <RadioGroup
              className='mt-3 mb-5 flex flex-row gap-2'
              aria-labelledby='demo-radio-buttons-group-label'
              defaultValue={watch('appliesTo')}
            >
              <FormControlLabel
                value='entireProducts'
                control={<Radio />}
                label={t('entire_products')}
                {...register('appliesTo')}
              />
              <FormControlLabel
                value='selectedProducts'
                control={<Radio />}
                label={t('selected_products')}
                {...register('appliesTo')}
              />
            </RadioGroup>

            {watch('appliesTo') === 'selectedProducts' && (
              <ComboBox
                loading={isFetching}
                label={t('select_product')}
                options={productOptions}
                values={selectedProducts}
                onChange={products =>
                  setProducts(products as CrmComboBoxType[])
                }
                inputSearchValue={searchProductVal}
                onInputChange={setSearchProductVal}
                multiple
                scrolledToBottomCallback={handleProductScrolledToBottom}
              />
            )}

            <ComboBox
              loading={isFetching}
              label={t('exclude_product')}
              options={excludeProductOption}
              values={excludedProducts}
              onChange={products =>
                setExcludeProduct(products as CrmComboBoxType[])
              }
              inputSearchValue={searchExcludeProduct}
              onInputChange={setSearchExcludeProduct}
              multiple
              scrolledToBottomCallback={handleProductScrolledToBottom}
            />

            <Typography variant='subtitle1' className='mt-7'>
              {t('apply_category')}
            </Typography>

            <ComboBox
              loading={fetchingCategory}
              label={t('select_category')}
              options={categoryOption}
              values={selectedCategory}
              onChange={category => setCategory(category as CrmComboBoxType[])}
              inputSearchValue={searchCategoryVal}
              onInputChange={setSearchCategoryVal}
              multiple
              scrolledToBottomCallback={handleCategoryScrollToBottom}
            />

            <ComboBox
              loading={fetchingCategory}
              label={t('exclude_category')}
              options={excludeCategoryOption}
              values={excludedCategory}
              onChange={products =>
                setExcludeCategory(products as CrmComboBoxType[])
              }
              inputSearchValue={searchExcludeCategory}
              onInputChange={setSearchExcludeCategory}
              multiple
              scrolledToBottomCallback={handleCategoryScrollToBottom}
            />
          </div>

          <div className='sixth-form-element'>
            <Typography variant='subtitle1' className='mt-7'>
              {t('active_plan')}
            </Typography>

            <div className='my-5'>
              <RHFRadioGroup
                name='activeDates'
                options={[
                  { label: t('always_active'), value: 'alwaysActive' },
                  { label: t('expires_at'), value: 'expiresAt' },
                  { label: t('relative'), value: 'relative' },
                ]}
              />
            </div>

            {watch('activeDates') === 'expiresAt' && (
              <DatePicker
                disablePast
                inputFormat='yyyy/MM/dd'
                renderInput={props => <TextField {...props} />}
                label={t('expires_at')}
                className='mt-5'
                value={activeDate}
                onChange={setActiveDate}
              />
            )}

            {watch('activeDates') === 'relative' && (
              <RHFTextField
                name='relative'
                type='number'
                placeholder={t('no_of_days')}
                className='mt-5'
                label={t('relative')}
                helperText={t('created_coupon_rule')}
              />
            )}
          </div>

          <div className='seventh-form-element'>
            <Typography variant='subtitle1' className='mt-7'>
              {t('coupon_usage')}
            </Typography>

            <div className='my-5'>
              <RHFRadioGroup
                name='couponUsage'
                options={[
                  { label: t('unlimited'), value: 'unlimited' },
                  { label: t('limited'), value: 'limited' },
                ]}
              />
            </div>

            {watch('couponUsage') === 'limited' && (
              <RHFTextField
                name='couponUsageLimit'
                type='number'
                placeholder={t('coupon_usage_limit')}
                className='mt-5'
                label={t('coupon_usage_limit')}
              />
            )}
          </div>

          <RHFTextField
            name='customNameProperty'
            placeholder={t('custom_name_property')}
            className='my-5'
            label={t('custom_name_property')}
            helperText={t('custom_property_helpText')}
          />
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <RHFCheckbox
              name='excludeSaleItems'
              label={t('exclude_sale_items')}
            />
            <RHFCheckbox name='freeShipping' label={t('allow_free_shipping')} />
            <RHFCheckbox
              name='individualCoupon'
              label={t('individual_use_only')}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            aria-label={t('login')}
            loading={submitting}
            className='mt-5 py-6'
            loadingPosition='start'
          >
            {t('save')}
          </LoadingButton>
        </FormProvider>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            paddingTop: '50px',
          }}
        >
          <Typography variant='h5'>{t('you_have_no_store_added')}</Typography>
          <LoadingButton
            sx={{ marginTop: '35px' }}
            variant='contained'
            onClick={() =>
              navigate(
                DISCOUNT_COUPONS_NAVIGATION.general.createStore(portalId)
              )
            }
            startIcon={<Iconify icon='eva:plus-fill' />}
            loading={false}
          >
            {t('create_new_store')}
          </LoadingButton>
        </Box>
      )}
    </div>
  );
};

export default CouponForm;
